import React from "react";
import { Hero } from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout, StreamfieldBody } from "../../components";
import { LegalTemplateQuery } from "./index.generated";

type PpLegalPage = {
  data: LegalTemplateQuery;
};

export default function LegalPage({
  data: {
    cms: { site },
  },
}: PpLegalPage): React.ReactElement | null {
  if (!site?.page) {
    return <Layout />;
  }

  return (
    <Layout>
      <Hero headline={site.page.headline} />
      <StreamfieldBody blocks={site?.page?.body} />
    </Layout>
  );
}

export const query = graphql`
  query LegalTemplate($slug: String) {
    cms {
      site(hostname: "www.italymondo.com") {
        page(slug: $slug) {
          ... on CMS_IMLegalPage {
            body {
              ... on CMS_RichTextBlock {
                __typename
                value
              }
              ... on CMS_HeadingBlock {
                __typename
                heading
              }
            }
            headline
          }
        }
      }
    }
  }
`;
